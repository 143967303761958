import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import { authorKamil as author } from 'data/authors';

import img from 'img/blog/updates/engagement-score.png';
import img1 from 'img/blog/updates/engagement-score-screen.png';
import styled from 'styled-components';

const Container = styled.div`
  h3,
  h2 {
    font-weight: 700;
    letter-spacing: 0.01em;
    margin: 2em 0 0.8em;
  }
  h3 {
    font-size: 1.1rem;
    line-height: 1.8rem;
  }
  h2 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  img.img-shadow {
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);
  }
`;

const Content = () => {
  return (
    <Container>
      <h3>Save your time by watching the most valuable recordings.</h3>

      <p>
        Each recording is evaluated according to the level of user involvement in a given session.
        This way, you can find out which records are worth watching.
      </p>

      <img
        src={img1}
        alt="engagement-score"
        className="img-shadow img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="Watch the most valuable recordings"
      />

      <p className="mt-3">
        We hope you like it! Other new improvements are coming soon. If you think something needs
        improvement, or do you have an idea for functionality? Write to us:{' '}
        <a href="mailto:hello@livesession.io">hello@livesession.io</a>
      </p>
    </Container>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Engagement score for each recording',
  url: '/blog/engagement-score/',
  description: 'Save your time by watching the most valuable recordings.',
  author,
  img,
  date: '2019-08-31',
  category: 'Product Updates',
  group: 'updates',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    group={frontmatter.group}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    descriptionSocial={frontmatter.descriptionSocial || frontmatter.description}
  />
);
